/**
 * Line Tag
 * 設置方法 https://campus.line.biz/line-ads/courses/preparation/lessons/da-2-2-5
 * 管理画面 https://manager.line.biz/account/@510bvevt/linetag
 */

export const lineBaseTagScript = {
  innerHTML: `
    (function(g,d,o){
      g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
      var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
      var s=d.createElement('script');s.async=1;
      s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
      var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
    })(window, document);
    _lt('init', {
      customerType: 'account',
      tagId: '231ac114-02b6-47be-ba30-3b6854c2d683'
      });
    _lt('send', 'pv', ['231ac114-02b6-47be-ba30-3b6854c2d683']);
  `,
}

export const pushLineCartInCode = () => {
  if (window && window._lt) {
    window._lt(
      "send",
      "cv",
      {
        type: "cartin",
      },
      ["231ac114-02b6-47be-ba30-3b6854c2d683"],
    )
  }
}

export const pushLineConversionCode = () => {
  if (window && window._lt) {
    window._lt(
      "send",
      "cv",
      {
        type: "Conversion",
      },
      ["231ac114-02b6-47be-ba30-3b6854c2d683"],
    )
  }
}
